<script>
import AppModal from "@/components/Ui/AppModal.vue";

export default {
  name: "UnauthNotification",
  components: { AppModal },
  emits: ["close"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isOpenNotification() {
      return this.isOpen;
    },
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },

    getAuthView() {
      this.$router.push({
        name: "LoginView",
        query: { blog: "createBlog" },
      });
      this.handleClose();
    },
  },
};
</script>

<template>
  <AppModal v-if="isOpenNotification" @close="handleClose">
    <div class="trial-period-notification">
      <span> <h2>Авторизация РДО</h2></span>

      <p>Что бы оставлять реакции войдите или зарегистрируйтесь на платформе</p>

      <div class="trial-period-notification__buttons">
        <button
          class="button button_full-width"
          type="button"
          @click="getAuthView"
        >
          Войти
        </button>
        <button
          class="button button_secondary button_full-width"
          type="button"
          @click="getAuthView"
        >
          Зарегистрироваться
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style lang="scss" scoped>
.trial-period-notification {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    display: flex;
    justify-content: left;
    width: inherit;
    margin-bottom: 8px;

    > h2 {
      color: $text-primary;
      @include h-2;
    }
  }

  > p {
    color: $text-second;
    @include s-text;
    margin-bottom: 24px;
    text-align: left;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
